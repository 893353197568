import { lazy } from "react";

const RpsQuestionnaire = lazy(() =>
  import("../../modules/employee/rps_questionnaire/containers/RpsQuestionnaire")
);

export const rpsQuestionnaire = {
  path: "/rps/questionnaire",
  component: RpsQuestionnaire
};

 import { ACTIONS } from "./../constants"


export default (state = { isLoading: false , password: false, error: null}, action) => {
  const {  payload,  type } = action
  switch (type) {
    case ACTIONS.CHANGE_PASSWORD_INIT : {
       return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.CHANGE_PASSWORD_FAILED : {
      return { ...state, password:false , error: payload, isLoading: false}
    }
    case ACTIONS.CHANGE_PASSWORD_SUCCEDED : {
      return { ...state, password: true, error: null, isLoading: false}
    }
    default: {
      return state
    }
  }

}
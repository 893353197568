import * as authRoutes from './authentication'
import * as confidentiality from './confidentiality'

export default {
  path: "/auth",
  routes: {
    ...authRoutes,
    ...confidentiality
  }
}

export const ACTIONS = {
  FETCH_EMPLOYEE_INVITATIONS_INIT: "FETCH_EMPLOYEE_INVITATIONS_INIT",
  FETCH_EMPLOYEE_INVITATIONS_SUCCEDED: "FETCH_EMPLOYEE_INVITATIONS_SUCCEDED",
  FETCH_EMPLOYEE_INVITATIONS_FAILED: "FETCH_EMPLOYEE_INVITATIONS_FAILED",

  CLEAR_EMPLOYEE_INVITATION_STORE: "CLEAR_EMPLOYEE_INVITATION_STORE",

  CREATE_EMPLOYEE_INVITATION_INIT: "CREATE_EMPLOYEE_INVITATION_INIT",
  CREATE_EMPLOYEE_INVITATION_SUCCEDED: "CREATE_EMPLOYEE_INVITATION_SUCCEDED",
  CREATE_EMPLOYEE_INVITATION_FAILED: "CREATE_EMPLOYEE_INVITATION_FAILED",

  CHECK_EMPLOYEE_INVITATION_INIT: "CHECK_EMPLOYEE_INVITATION_INIT",
  CHECK_EMPLOYEE_INVITATION_SUCCEDED: "CHECK_EMPLOYEE_INVITATION_SUCCEDED",
  CHECK_EMPLOYEE_INVITATION_FAILED: "CHECK_EMPLOYEE_INVITATION_FAILED",

  FETCH_EMPLOYEE_INVITATION_INIT: "FETCH_EMPLOYEE_INVITATION_INIT",
  FETCH_EMPLOYEE_INVITATION_SUCCEDED: "FETCH_EMPLOYEE_INVITATION_SUCCEDED",
  FETCH_EMPLOYEE_INVITATION_FAILED: "FETCH_EMPLOYEE_INVITATION_FAILED",

  EDIT_EMPLOYEE_INVITATION_INIT: "EDIT_EMPLOYEE_INVITATION_INIT",
  EDIT_EMPLOYEE_INVITATION_SUCCEDED: "EDIT_EMPLOYEE_INVITATION_SUCCEDED",
  EDIT_EMPLOYEE_INVITATION_FAILED: "EDIT_EMPLOYEE_INVITATION_FAILED",

  DELETE_EMPLOYEE_INVITATION_INIT: "DELETE_EMPLOYEE_INVITATION_INIT",
  DELETE_EMPLOYEE_INVITATION_SUCCEDED: "DELETE_EMPLOYEE_INVITATION_SUCCEDED",
  DELETE_EMPLOYEE_INVITATION_FAILED: "DELETE_EMPLOYEE_INVITATION_FAILED",

  SEND_EMPLOYEE_INVITATION_INIT: "SEND_EMPLOYEE_INVITATION_INIT",
  SEND_EMPLOYEE_INVITATION_SUCCEDED: "SEND_EMPLOYEE_INVITATION_SUCCEDED",
  SEND_EMPLOYEE_INVITATION_FAILED: "SEND_EMPLOYEE_INVITATION_FAILED",

  RESEND_EMPLOYEES_INVITATION_INIT: "RESEND_EMPLOYEES_INVITATION_INIT",
  RESEND_EMPLOYEES_INVITATION_SUCCEDED: "RESEND_EMPLOYEES_INVITATION_SUCCEDED",
  RESEND_EMPLOYEES_INVITATION_FAILED: "RESEND_EMPLOYEE_INVITATION_FAILED",

  FILTER_EMPLOYEE_INVITATIONS: "FILTER_EMPLOYEE_INVITATIONS",
};

export const ENDPOINT = {
  EMPLOYEE_INVITATIONS: "/api/department/:departmentParam/employee-invitations",
  EMPLOYEE_INVITATION:
    "/api/department/:departmentParam/employee-invitation/:employeeParam",
  EMPLOYEE_INVITATION_DELETE:
    "/api/department/:departmentParam/employee-invitation/:employeeParam/delete",
  SEND_EMPLOYEE_INVITATION:
    "/api/department/:departmentParam/employee-invitation/:employeeParam/send",
  CHECK_EMPLOYEE_INVITATION:
    "/api/department/:departmentParam/employee-invitations/check",
  RESEND_EMPLOYEES_INVITATION:
    "/api/department/:departmentParam/employee-invitations/resend",
};

import { ROLES } from "../../constants";

import * as inviteManager from "./inviteManager";
import * as company from "./company";
import * as department from "./department";
import * as dashbord from "./dashbord";
import * as manager from "./manager";
import * as employee from "./employee";
import * as questionnaire from "./questionnaire";
import * as messages from "./messaging";
import * as subadmins from "./subadmins";
import * as subContactList from "./contacts";
import * as subQuoteList from "./quotes";
import * as subFormationList from "./formations";

export default {
  path: "/admin",
  role: ROLES.ADMIN,

  routes: {
    ...inviteManager,
    ...company,
    ...department,
    ...dashbord,
    ...manager,
    ...employee,
    ...questionnaire,
    ...messages,
    ...subadmins,
    ...subContactList,
    ...subQuoteList,
    ...subFormationList,
  },
};

import React, { useEffect } from 'react'
import { Route } from "react-router-dom";
// 404 page
import PageNotFound from "./PageNotFound";

const Loader = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      return (   <Route component={PageNotFound} />
)
    
    }, 20000);
    return () => clearTimeout(timer);
  }, []);
  return (<div className="preloader">
  <div className="loader"></div>
</div>
)
}
  

export default Loader

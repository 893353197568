export const ACTIONS = {
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: 'RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED:
    'RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED:
    'RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED',

  GET_QUESTIONNAIRES_INIT: 'questionnaire@GET_QUESTIONNAIRES_INIT',
  GET_QUESTIONNAIRES_SUCCEDED: 'questionnaire@GET_QUESTIONNAIRES_SUCCEDED',
  GET_QUESTIONNAIRES_FAILED: 'questionnaire@GET_QUESTIONNAIRES_FAILED',

  FETCH_MESSAGES_INIT: 'FETCH_MESSAGES_INIT',
  FETCH_MESSAGES_SUCCEDED: 'FETCH_MESSAGES_SUCCEDED',
  FETCH_MESSAGES_FAILED: 'FETCH_MESSAGES_FAILED',

  FETCH_UNREAD_MESSAGES_INIT: 'FETCH_UNREAD_MESSAGES_INIT',
  FETCH_UNREAD_MESSAGES_SUCCEDED: 'FETCH_UNREAD_MESSAGES_SUCCEDED',
  FETCH_UNREAD_MESSAGES_FAILED: 'FETCH_UNREAD_MESSAGES_FAILED'
};

export const ENDPOINT = {
  RETREIVE_QUSTIONNAIRE_EMPLOYEE: '/api/employee/questionnaire/:param',
  QUESTIONNAIRES_EMPLOYEE: '/api/employee/questionnaires',
  FETCH_MESSAGES_EMPLOYEE: '/api/employee/:param/messages',
  FETCH_UNREAD_MESSAGES: '/api/message/unread'
};

import React from "react";

const updateEmail = React.lazy(() =>
  import("../../modules/employee/updateEmail/containers/UpdateEmail")
);

export const update_email = {
  path: "/updateEmail",
  component: updateEmail,
};

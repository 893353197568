export const ACTIONS = {
  FETCH_COMPANIES_INIT: "FETCH_COMPANIES_INIT",
  FETCH_COMPANIES_SUCCEDED: "FETCH_COMPANIES_SUCCEDED",
  FETCH_COMPANIES_FAILED: "FETCH_COMPANIES_FAILED",

  CLEAR_COMPANY_FORM: "CLEAR_COMPANY_FORM",

  CREATE_COMPANY_INIT: "CREATE_COMPANY_INIT",
  CREATE_COMPANY_SUCCEDED: "CREATE_COMPANY_SUCCEDED",
  CREATE_COMPANY_FAILED: "CREATE_COMPANY_FAILED",

  FETCH_COMPANY_INIT: "FETCH_COMPANY_INIT",
  FETCH_COMPANY_SUCCEDED: "FETCH_COMPANY_SUCCEDED",
  FETCH_COMPANY_FAILED: "FETCH_COMPANY_FAILED",

  EDIT_COMPANY_INIT: "EDIT_COMPANY_INIT",
  EDIT_COMPANY_SUCCEDED: "EDIT_COMPANY_SUCCEDED",
  EDIT_COMPANY_FAILED: "EDIT_COMPANY_FAILED",

  DELETE_COMPANY_INIT: "DELETE_COMPANY_INIT",
  DELETE_COMPANY_SUCCEDED: "DELETE_COMPANY_SUCCEDED",
  DELETE_COMPANY_FAILED: "DELETE_COMPANY_FAILED",

  FILTER_COMPANIES: "FILTER_COMPANIES",

  EDIT_COMPANY_THRESHOLD_INIT: "EDIT_COMPANY_THRESHOLD_INIT",
  EDIT_COMPANY_THRESHOLD_SUCCEDED: "EDIT_COMPANY_THRESHOLD_SUCCEDED",
  EDIT_COMPANY_THRESHOLD_FAILED: "EDIT_COMPANY_THRESHOLD_FAILED",

  FETCH_COMPANY_THRESHOLD_INIT: "FETCH_COMPANY_THRESHOLD_INIT",
  FETCH_COMPANY_THRESHOLD_SUCCEDED: "FETCH_COMPANY_THRESHOLD_SUCCEDED",
  FETCH_COMPANY_THRESHOLD_FAILED: "FETCH_COMPANY_THRESHOLD_FAILED",
};

export const ENDPOINT = {
  COMPANIES: "/api/companies",
  COMPANY: "/api/company/:param",
  COMPANY_THRESHOLD: "/api/company/:param/threshold",
  COMPANY_RETREIVE_THRESHOLD: "/api/company/:param/departments/more_threshold",
};

import React from 'react'

const PolicyPrivacy = React.lazy( () => import("../../modules/anonymous/authentication/containers/PolicyPrivacy"))
const CGU = React.lazy( () => import("../../modules/anonymous/authentication/containers/CGU"))

export const privacypolicy = {
    path: "/policy_privacy",
    component: PolicyPrivacy
  }
  
  export const cgu = {
    path: "/cgu",
    component: CGU
  }
  
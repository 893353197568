import React from "react";

const Register = React.lazy(() =>
  import("../../modules/anonymous/authentication/containers/Register")
);
const Logout = React.lazy(() =>
  import("../../modules/anonymous/authentication/containers/Logout")
);
const LogoutConfirmation = React.lazy(() =>
  import("../../modules/anonymous/authentication/containers/LogoutConfirmation")
);
const LogoutEmployee = React.lazy(() =>
  import("../../modules/anonymous/authentication/containers/LogoutEmployee")
);
const Login = React.lazy(() =>
  import("../../modules/anonymous/authentication/containers/Login")
);
const LoginAdmin = React.lazy(() =>
  import("../../modules/anonymous/authentication/containers/LoginAdmin")
);
const ForgetPasswordEmail = React.lazy(() =>
  import(
    "../../modules/anonymous/authentication/containers/ForgotPasswordSendEmail"
  )
);
const ResetPassword = React.lazy(() =>
  import("../../modules/anonymous/authentication/containers/ResetPassword")
);

export const register = {
  path: "/register/:param",
  component: Register,
};

export const reset_password = {
  path: "/reset_password",
  component: ResetPassword,
};

export const login = {
  path: "/login",
  component: Login,
};
export const login_admin = {
  path: "/login/admin",
  component: LoginAdmin,
};
export const forgot_password_email = {
  path: "/forgot_password",
  component: ForgetPasswordEmail,
};

export const logout = {
  component: Logout,
};

export const logoutConfirmation = {
  component: LogoutConfirmation,
};

export const logoutEmployee = {
  component: LogoutEmployee,
};

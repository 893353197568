export const ACTIONS = {
  FETCH_MANAGER_INVITATIONS_INIT: "FETCH_MANAGER_INVITATIONS_INIT",
  FETCH_MANAGER_INVITATIONS_SUCCEDED: "FETCH_MANAGER_INVITATIONS_SUCCEDED",
  FETCH_MANAGER_INVITATIONS_FAILED: "FETCH_MANAGER_INVITATIONS_FAILED",

  CLEAR_MANAGER_INVITATION_STORE: "CLEAR_MANAGER_INVITATION_STORE",

  CREATE_MANAGER_INVITATION_INIT: "CREATE_MANAGER_INVITATION_INIT",
  CREATE_MANAGER_INVITATION_SUCCEDED: "CREATE_MANAGER_INVITATION_SUCCEDED",
  CREATE_MANAGER_INVITATION_FAILED: "CREATE_MANAGER_INVITATION_FAILED",

  FETCH_MANAGER_INVITATION_INIT: "FETCH_MANAGER_INVITATION_INIT",
  FETCH_MANAGER_INVITATION_SUCCEDED: "FETCH_MANAGER_INVITATION_SUCCEDED",
  FETCH_MANAGER_INVITATION_FAILED: "FETCH_MANAGER_INVITATION_FAILED",

  EDIT_MANAGER_INVITATION_INIT: "EDIT_MANAGER_INVITATION_INIT",
  EDIT_MANAGER_INVITATION_SUCCEDED: "EDIT_MANAGER_INVITATION_SUCCEDED",
  EDIT_MANAGER_INVITATION_FAILED: "EDIT_MANAGER_INVITATION_FAILED",

  DELETE_MANAGER_INVITATION_INIT: "DELETE_MANAGER_INVITATION_INIT",
  DELETE_MANAGER_INVITATION_SUCCEDED: "DELETE_MANAGER_INVITATION_SUCCEDED",
  DELETE_MANAGER_INVITATION_FAILED: "DELETE_MANAGER_INVITATION_FAILED",

  SEND_MANAGER_INVITATION_INIT: "SEND_MANAGER_INVITATION_INIT",
  SEND_MANAGER_INVITATION_SUCCEDED: "SEND_MANAGER_INVITATION_SUCCEDED",
  SEND_MANAGER_INVITATION_FAILED: "SEND_MANAGER_INVITATION_FAILED",

  FILTER_MANAGER_INVITATIONS: "FILTER_MANAGER_INVITATIONS"
}

export const ENDPOINT = {
  MANAGER_INVITATIONS: "/api/company/:companyParam/manager-invitations",
  MANAGER_INVITATION: "/api/company/:companyParam/manager-invitation/:managerParam",
  SEND_MANAGER_INVITATION: "/api/company/:companyParam/manager-invitation/:managerParam/send"
}

export const ACTIONS = {
  FETCH_COMPANY_GENERAL_STATS_INIT: "FETCH_COMPANY_GENERAL_STATS_INIT",
  FETCH_COMPANY_GENERAL_STATS_SUCCEDED: "FETCH_COMPANY_GENERAL_STATS_SUCCEDED",
  FETCH_COMPANY_GENERAL_STATS_FAILED: "FETCH_COMPANY_GENERAL_STATS_FAILED",

  FETCH_DEPARTMENT_GENERAL_STATS_INIT: "FETCH_DEPARTMENT_GENERAL_STATS_INIT",
  FETCH_DEPARTMENT_GENERAL_STATS_SUCCEDED:
    "FETCH_DEPARTMENT_GENERAL_STATS_SUCCEDED",
  FETCH_DEPARTMENT_GENERAL_STATS_FAILED:
    "FETCH_DEPARTMENT_GENERAL_STATS_FAILED",
};

export const ENDPOINT = {
  COMPANY_GENERAL_STATS: "/api/company/:companyParam/hrd-general-stats",
  DEPARMTNET_GENERAL_STATS: "/api/hrd-general-stats/:departmentParam",
};

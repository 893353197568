import React from "react";

const FormationList = React.lazy(() =>
  import("../../modules/admin/subadmin/containers/FormationList")
);

export const subFormationList = {
  path: "/formations",
  component: FormationList,
};

export const ACTIONS = {
    RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: "RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT",
    RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED: "RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED",
    RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED: "RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED",

    GET_QUESTIONNAIRES_INIT: 'questionnaire@GET_QUESTIONNAIRES_INIT',
    GET_QUESTIONNAIRES_SUCCEDED: 'questionnaire@GET_QUESTIONNAIRES_SUCCEDED',
    GET_QUESTIONNAIRES_FAILED: 'questionnaire@GET_QUESTIONNAIRES_FAILED'
}

export const ENDPOINT = {
    QUESTIONNAIRES_EMPLOYEE: "/api/employee/questionnaires",
    RETREIVE_QUESTIONNAIRE_EMPLOYEE: "/api/employee/questionnaire"
}

import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Notifications from "react-notification-system-redux";

//store
import { sessionService } from "redux-react-session";
import { useSelector } from "react-redux";

//Google Analytics
import ReactGA from "react-ga";
import { GA_KEY } from "./constants";
//sentry
import * as Sentry from "@sentry/react";
//routes
import routes from "./routes";
// 404 page
import PageNotFound from "./components/PageNotFound";

const { NODE_ENV } = process.env;

const AnonymousLayout = React.lazy(() =>
  import("./modules/anonymous/layout/containers/Layout")
);
const AdminLayout = React.lazy(() =>
  import("./modules/admin/layout/containers/Layout")
);
const ModeratorLayout = React.lazy(() =>
  import("./modules/moderator/layout/containers/Layout")
);
const EmployeeLayout = React.lazy(() =>
  import("./modules/employee/layout/containers/Layout")
);

const style = {
  NotificationItem: {
    DefaultStyle: {
      backgroundColor: "white"
    },
    success: {
      color: "#00aa25"
    },
    success: {
      color: "#00aa25"
    }
  },
  Dismiss: {
    DefaultStyle: {
      width: 9,
      height: 9,
      top: "24px",
      right: "22px",
      backgroundColor: "transparent",
      color: "#7C7C7C",
      zIndex: 1
    },
    success: {
      backgroundColor: "transparent",
      color: "#7C7C7C"
    },
    error: {
      backgroundColor: "transparent",
      color: "#7C7C7C"
    }
  }
};
NODE_ENV === "production" && ReactGA.initialize(GA_KEY);
const App = () => {
  const notifications = useSelector((state) => state.notifications);
  useEffect(() => {
    if (NODE_ENV === "production") {
      ReactGA.pageview("/");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });

  return (
    <>
      {notifications && (
        <Notifications
          notifications={notifications}
          style={style}
          noAnimation={true}
        />
      )}
      <Switch>
        <Route path={routes.anonymous.path} component={AnonymousLayout} />
        <Route
          onEnter={sessionService.checkAuth}
          path={routes.moderator.path}
          component={ModeratorLayout}
        />
        <Route
          onEnter={sessionService.checkAuth}
          path={routes.admin.path}
          component={AdminLayout}
        />
        <Route
          onEnter={sessionService.checkAuth}
          path={routes.employee.path}
          component={EmployeeLayout}
        />
        <Redirect
          from="/"
          to={routes.anonymous.path + routes.anonymous.routes.login.path}
        />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Sentry.withProfiler(App);

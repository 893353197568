export const ACTIONS = {
  FETCH_DEPARMTNETS_INIT: "dashbord@FETCH_DEPARMTNETS_INIT",
  FETCH_DEPARMTNETS_SUCCEDED: "dashbord@FETCH_DEPARMTNETS_SUCCEDED",
  FETCH_DEPARMTNETS_FAILED: "dashbord@FETCH_DEPARMTNETS_FAILED",
  FETCH_BREAKDOWN_OF_FAILURES_INIT: "dashbord@FETCH_BREAKDOWN_OF_FAILURES_INIT",
  FETCH_BREAKDOWN_OF_FAILURES_SUCCEDED:
    "dashbord@FETCH_BREAKDOWN_OF_FAILURES_COMPANY_SUCCEDED",
  FETCH_BREAKDOWN_OF_FAILURES_FAILED:
    "dashbord@FETCH_BREAKDOWN_OF_FAILURES_FAILED",
  FETCH_NEED_FOR_INTERVENTIONS_INIT:
    "dashbord@FETCH_NEED_FOR_INTERVENTIONS_INIT",
  FETCH_NEED_FOR_INTERVENTIONS_SUCCEDED:
    "dashbord@FETCH_NEED_FOR_INTERVENTIONS_SUCCEDED",
  FETCH_NEED_FOR_INTERVENTIONS_FAILED:
    "dashbord@FETCH_NEED_FOR_INTERVENTIONS_FAILED",
  BAROMETER_INIT: "dashbord@BAROMETER_INIT",
  BAROMETER_SUCCEDED: "dashbord@BAROMETER_SUCCEDED",
  BAROMETER_FAILED: "dashbord@BAROMETER_FAILED",
  MORE_THRESHOLD_INIT: "MORE_THRESHOLD_INIT",
  MORE_THRESHOLD_SUCCEDED: "MORE_THRESHOLD_SUCCEDED",
  MORE_THRESHOLD_FAILED: "MORE_THRESHOLD_FAILED",
};

export const ENDPOINT = {
  ALL_DEPARTMENTS: "/api/company/:companyParam/departments/all",
  BREAKDOWN_OF_FAILURES:
    "/api/hrd-general-monitoring/breakdown-of-failures/:deparmentParam",
  BREAKDOWN_OF_FAILURES_COMPANY:
    "/api/company/:companyParam/hrd-general-monitoring/breakdown-of-failures",
  NEED_FOR_INTERVENTIONS:
    "/api/hrd-general-monitoring/need-for-intervention/:deparmentParam",
  NEED_FOR_INTERVENTIONS_COMPANY:
    "/api/company/:companyParam/hrd-general-monitoring/need-for-intervention",
  BAROMETER_COMPANY: "/api/company/:Param/hrd-general-monitoring/barometer",
  BAROMETER_DEPARTMENT: "/api/hrd-general-monitoring/barometer/:Param",
  MORE_THRESHOLD_COMPANY: "/api/company/:Param/departments/more_threshold ",
};

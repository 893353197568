import * as confidentiality from './confidentiality'



export default {
  path: "/public",

  routes: {
    ...confidentiality,
    
  }
}

import React from "react";

const Nutrition = React.lazy(() =>
  import("../../modules/employee/nutrition/containers/Nutrition")
);
const NutritionResult = React.lazy(() =>
  import("../../modules/employee/nutrition/containers/NutritionResultContainer")
);
const AlcoholConsumption = React.lazy(() =>
  import(
    "../../modules/employee/nutrition/containers/AlcoholConsumptionContainer"
  )
);
export const nutrition = {
  path: "/nutrition",
  component: Nutrition
};

export const nutritionResult = {
  path: "/nutritionResult",
  component: NutritionResult
};

export const alcoholConsumption = {
  path: "/alcoholConsumption",
  component: AlcoholConsumption
};

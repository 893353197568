export const ACTIONS = {
  CREATE_MEDICAL_RECORD_INIT: "CREATE_MEDICAL_RECORD_INIT",
  CREATE_MEDICAL_RECORD_SUCCEDED: "CREATE_MEDICAL_RECORD_SUCCEDED",
  CREATE_MEDICAL_RECORD_FAILED: "CREATE_MEDICAL_RECORD_FAILED",
  RETEIVE_MEDICAL_RECORD_INIT: "RETEIVE_MEDICAL_RECORD_INIT",
  RETEIVE_MEDICAL_RECORD_SUCCEDED: "RETEIVE_MEDICAL_RECORD_SUCCEDED",
  RETEIVE_MEDICAL_RECORD_FAILED: "RETEIVE_MEDICAL_RECORD_FAILED",

}

export const ENDPOINT = {
  CREATE_MEDICAL_RECORD: "/api/medical-record",
  RETEIVE_MEDICAL_RECORD: "/api/medical-record"
}

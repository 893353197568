import React from "react"

const QuoteList = React.lazy( () => import("../../modules/admin/subadmin/containers/QuoteList"))
 

export const subQuoteList = {
  path: "/quotes",
  component: QuoteList
}

 
import { ACTIONS } from "../constants";
import i18n from "../../../../i18n";

const defaultState = {
  isLoadingQuestionnaire: false,
  rpsQuestionnaireObject: {},
  isLoadingQuestionnaireState: false,
  page: 1,
  isLoading: false,
  item: null,
  error: null,
  currentQuestion: { treeparam: "", nodeparam: "" },
  rps_exit: false,
  karasekCategoryScore: {
    SUPPORT: 0,
    ASK_PSY: 0,
    AUTONOMY: 0
  },
  siegristCategoryScore: {
    EFFORT: 0,
    ACKNOWLEDGEMENT: 0
  },
  karasekCategories: [
    { id: "support", idForSend: "SUPPORT" },
    { id: "ask_psy", idForSend: "ASK_PSY" },
    { id: "autonomy", idForSend: "AUTONOMY" }
  ],
  siegristCategories: [
    { id: "effort", idForSend: "EFFORT" },
    { id: "acknowledgement", idForSend: "ACKNOWLEDGEMENT" }
  ],
  scores: [],
  autonomyAnsweredQuestions: [],
  supportAnsweredQuestions: [],
  askPsyAnsweredQuestions: [],
  effortAnsweredQuestions: [],
  acknowledgementAnsweredQuestions: [],
  karasekScorsSaved: null,
  siegristScorsSaved: null,
  rpsQuestionsAnswered: [],
  completion: 0,
  askContinue: false,
  karasekCurrentQuesion: 1,
  siegristCurrentQuesion: 1,
  karasek_exit: false,
  lastAnswerId: 0
};

export default (
  state = {
    isLoadingQuestionnaire: false,
    rpsQuestionnaireObject: {},
    isLoadingQuestionnaireState: false,
    page: 1,
    isLoading: false,
    item: null,
    error: null,
    currentQuestion: { treeparam: "", nodeparam: "" },
    rps_exit: false,
    karasekCategoryScore: {
      SUPPORT: 0,
      ASK_PSY: 0,
      AUTONOMY: 0
    },
    siegristCategoryScore: {
      EFFORT: 0,
      ACKNOWLEDGEMENT: 0
    },
    karasekCategories: [
      { id: "support", idForSend: "SUPPORT" },
      { id: "ask_psy", idForSend: "ASK_PSY" },
      { id: "autonomy", idForSend: "AUTONOMY" }
    ],
    siegristCategories: [
      { id: "effort", idForSend: "EFFORT" },
      { id: "acknowledgement", idForSend: "ACKNOWLEDGEMENT" }
    ],
    scores: [],
    autonomyAnsweredQuestions: [],
    supportAnsweredQuestions: [],
    askPsyAnsweredQuestions: [],
    effortAnsweredQuestions: [],
    acknowledgementAnsweredQuestions: [],
    karasekScorsSaved: null,
    siegristScorsSaved: null,
    rpsQuestionsAnswered: [],
    completion: 0,
    askContinue: false,
    karasekCurrentQuesion: 1,
    siegristCurrentQuesion: 1,
    karasek_exit: false,
    lastAnswerId: 0
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CREATE_RPS_QUESTIONNAIRE_INIT: {
      return {
        ...state,
        isLoadingQuestionnaire: true,
        rpsQuestionnaireObject: {},
        error: null
      };
    }
    case ACTIONS.CREATE_RPS_QUESTIONNAIRE_SUCCEDED: {
      return {
        ...state,
        rpsQuestionnaireObject: payload,
        isLoadingQuestionnaire: false,
        error: null
      };
    }
    case ACTIONS.CREATE_RPS_QUESTIONNAIRE_FAILED: {
      return { ...state, isLoadingQuestionnaire: false, error: payload };
    }
    case ACTIONS.GET_RPS_STATE_INIT: {
      return { ...state, isLoadingQuestionnaireState: true };
    }
    case ACTIONS.GET_RPS_STATE_SUCCEDED: {
      const newState = payload;

      if (Object.entries(newState).length > 0) {
        return { ...newState, isLoadingQuestionnaireState: false };
      }
      return { ...state, ...defaultState, isLoadingQuestionnaireState: false };
    }
    case ACTIONS.NEXT_PAGE: {
      const page = state.page + 1;
      return { ...state, page };
    }
    case ACTIONS.FETCH_RPS_QUESTION_INIT: {
      return {
        ...state,
        isLoading: true,
        item: null,
        error: null
      };
    }
    case ACTIONS.FETCH_RPS_QUESTION_SUCCEDED: {
      return {
        ...state,
        item: payload,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_RPS_QUESTION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }
    case ACTIONS.RPS_NEXT_QUESTION: {
      const rpsQuestionsAnswered = [
        ...state.rpsQuestionsAnswered,
        state.currentQuestion
      ];
      return { ...state, rpsQuestionsAnswered, currentQuestion: payload };
    }
    case ACTIONS.EXIT_PAGE: {
      return { ...state, rps_exit: true };
    }
    case ACTIONS.UPDATE_RPS_SCORE: {
      const rpsQuestionnaire = { ...state.rpsQuestionnaireObject };
      if (rpsQuestionnaire.questionnaireType === "KARASEK") {
        const karasekCategoryScore = { ...state.karasekCategoryScore };
        (payload || []).forEach((element) => {
          const key = state.karasekCategories.find(
            (cat) => cat.id === element.name
          );
          if (key && key.idForSend) {
            karasekCategoryScore[key.idForSend] += element.value;
          }
        });

        let newArrayScores = [...state.scores];
        if (newArrayScores.length > 0) {
          for (let i = 0; i < payload.length; i++) {
            let indexScore = newArrayScores
              .map((el) => el.name)
              .indexOf(payload[i].name);
            if (indexScore > -1) {
              newArrayScores[indexScore] = {
                id: payload[i].id,
                value: newArrayScores[indexScore].value + payload[i].value,
                name: payload[i].name
              };
            } else {
              newArrayScores.push(payload[i]);
            }
          }
        } else {
          newArrayScores = payload;
        }
        return { ...state, scores: newArrayScores, karasekCategoryScore };
      }
      if (rpsQuestionnaire.questionnaireType === "SIEGRIST") {
        const siegristCategoryScore = { ...state.siegristCategoryScore };
        (payload || []).forEach((element) => {
          const key = state.siegristCategories.find(
            (cat) => cat.id === element.name
          );
          if (key && key.idForSend) {
            siegristCategoryScore[key.idForSend] += element.value;
          }
        });

        let newArrayScores = [...state.scores];
        if (newArrayScores.length > 0) {
          for (let i = 0; i < payload.length; i++) {
            let indexScore = newArrayScores
              .map((el) => el.name)
              .indexOf(payload[i].name);
            if (indexScore > -1) {
              newArrayScores[indexScore] = {
                id: payload[i].id,
                value: newArrayScores[indexScore].value + payload[i].value,
                name: payload[i].name
              };
            } else {
              newArrayScores.push(payload[i]);
            }
          }
        } else {
          newArrayScores = payload;
        }
        return { ...state, scores: newArrayScores, siegristCategoryScore };
      }
    }
    case ACTIONS.SAVE_RPS_QUESTION_ANSWER: {
      if (payload.category === "support") {
        let newAnswersSupport = [...state.supportAnsweredQuestions, payload];
        return { ...state, supportAnsweredQuestions: newAnswersSupport };
      }
      if (payload.category === "ask_psy") {
        let newAnswersAskPsy = [...state.askPsyAnsweredQuestions, payload];
        return { ...state, askPsyAnsweredQuestions: newAnswersAskPsy };
      }
      if (payload.category === "autonomy") {
        let newAnswersAutonomy = [...state.autonomyAnsweredQuestions, payload];
        return { ...state, autonomyAnsweredQuestions: newAnswersAutonomy };
      }
      if (payload.category === "effort") {
        let newAnswersEffort = [...state.effortAnsweredQuestions, payload];
        return { ...state, effortAnsweredQuestions: newAnswersEffort };
      }
      if (payload.category === "acknowledgement") {
        let newAnswersAcknowledgement = [
          ...state.acknowledgementAnsweredQuestions
        ];
        if (
          payload.question ===
          newAnswersAcknowledgement[newAnswersAcknowledgement.length - 1]
            ?.question
        ) {
          newAnswersAcknowledgement.pop();
          newAnswersAcknowledgement = [...newAnswersAcknowledgement, payload];
        } else {
          newAnswersAcknowledgement = [
            ...state.acknowledgementAnsweredQuestions,
            payload
          ];
        }
        return {
          ...state,
          acknowledgementAnsweredQuestions: newAnswersAcknowledgement
        };
      }
    }
    case ACTIONS.SAVE_KARASEK_SCORES_SUCCEDED: {
      return { ...state, karasekScorsSaved: true };
    }
    case ACTIONS.SAVE_KARASEK_SCORES_FAILED: {
      return { ...state, karasekScorsSaved: false };
    }
    case ACTIONS.SAVE_SIEGRIST_SCORES_SUCCEDED: {
      return { ...state, siegristScorsSaved: true };
    }
    case ACTIONS.SAVE_SIEGRIST_SCORES_FAILED: {
      return { ...state, siegristScorsSaved: false };
    }
    case ACTIONS.ADD_COMPLETION: {
      const completion = state.completion + 2.3;
      return { ...state, completion };
    }
    case ACTIONS.DELETE_COMPLETION: {
      const completion = state.completion - 2.3;
      return { ...state, completion };
    }
    case ACTIONS.PREVIOUS_QUESTION: {
      let prevQuestion = state.rpsQuestionsAnswered.pop();
      const rpsQuestionsAnswered = [...state.rpsQuestionsAnswered];
      if (payload.page === 1) {
        if (state.supportAnsweredQuestions.length !== 0) {
          let answerId = state.supportAnsweredQuestions.pop().id;
          let newAnswersSupport = [...state.supportAnsweredQuestions];
          return {
            ...state,
            supportAnsweredQuestions: newAnswersSupport,
            rpsQuestionsAnswered,
            currentQuestion: prevQuestion,
            lastAnswerId: answerId
          };
        } else if (state.askPsyAnsweredQuestions.length !== 0) {
          let answerId = state.askPsyAnsweredQuestions.pop().id;
          let newAnswersAskpsy = [...state.askPsyAnsweredQuestions];
          return {
            ...state,
            askPsyAnsweredQuestions: newAnswersAskpsy,
            rpsQuestionsAnswered,
            currentQuestion: prevQuestion,
            lastAnswerId: answerId
          };
        } else if (state.autonomyAnsweredQuestions.length !== 0) {
          let answerId = state.autonomyAnsweredQuestions.pop().id;
          let newAnswersAutonomy = [...state.autonomyAnsweredQuestions];
          return {
            ...state,
            autonomyAnsweredQuestions: newAnswersAutonomy,
            rpsQuestionsAnswered,
            currentQuestion: prevQuestion,
            lastAnswerId: answerId
          };
        }
      }
      if (payload.page === 2) {
        if (state.acknowledgementAnsweredQuestions.length !== 0) {
          if (state.acknowledgementAnsweredQuestions.length === 11) {
            let itemsDeleted = state.acknowledgementAnsweredQuestions.splice(
              -2,
              2
            );
            let answerId = itemsDeleted[0].id;
            let newAnswersAcknowledgement = [
              ...state.acknowledgementAnsweredQuestions
            ];
            return {
              ...state,
              acknowledgementAnsweredQuestions: newAnswersAcknowledgement,
              rpsQuestionsAnswered,
              currentQuestion: prevQuestion,
              lastAnswerId: answerId
            };
          } else {
            let answerId = state.acknowledgementAnsweredQuestions.pop().id;
            let newAnswersAcknowledgement = [
              ...state.acknowledgementAnsweredQuestions
            ];
            return {
              ...state,
              acknowledgementAnsweredQuestions: newAnswersAcknowledgement,
              rpsQuestionsAnswered,
              currentQuestion: prevQuestion,
              lastAnswerId: answerId
            };
          }
        } else if (state.effortAnsweredQuestions.length !== 0) {
          let answerId = state.effortAnsweredQuestions.pop().id;
          let newAnswersEffort = [...state.effortAnsweredQuestions];
          return {
            ...state,
            effortAnsweredQuestions: newAnswersEffort,
            rpsQuestionsAnswered,
            currentQuestion: prevQuestion,
            lastAnswerId: answerId
          };
        }
      }
    }
    case ACTIONS.ASK_CONTINUE: {
      return { ...state, askContinue: payload };
    }
    case ACTIONS.ADD_QUESTION: {
      if (payload.page === 1) {
        const karasekCurrentQuesion = state.karasekCurrentQuesion + 1;
        return { ...state, karasekCurrentQuesion };
      }
      if (payload.page === 2) {
        const siegristCurrentQuesion = state.siegristCurrentQuesion + 1;
        return { ...state, siegristCurrentQuesion };
      }
    }
    case ACTIONS.MINUS_QUESTION: {
      if (payload.page === 1) {
        const karasekCurrentQuesion = state.karasekCurrentQuesion - 1;
        return { ...state, karasekCurrentQuesion };
      }
      if (payload.page === 2) {
        const siegristCurrentQuesion = state.siegristCurrentQuesion - 1;
        return { ...state, siegristCurrentQuesion };
      }
    }
    case ACTIONS.KARASEK_FINISHED: {
      return { ...state, karasek_exit: true };
    }
    default: {
      return state;
    }
  }
};

import React from "react";

const Dashboard = React.lazy(() =>
  import("../../modules/employee/dashboard/containers/Dashboard")
);
const UpdateInfoPerso = React.lazy(() =>
  import("../../modules/employee/dashboard/component/UpdateInfoPerso")
);
const UpdateAccountParams = React.lazy(() =>
  import("../../modules/employee/dashboard/component/UpdateAccountParams")
);
export const dashboard = {
  path: "/dashboard",
  component: Dashboard
};

export const updateInfoPerso = {
  path: "/update-info-perso",
  component: UpdateInfoPerso
};

export const updateAccountParams = {
  path: "/update-acount-params",
  component: UpdateAccountParams
};

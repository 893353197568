import { ACTIONS } from "../constants";

export default (
  state = {
    evolution: {},
    isLoading: false,
    error: null,
    isLoadingRecord: false,
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.RETEIVE_EVOLUTION_INIT: {
      return { ...state, isLoading: true };
    }
    case ACTIONS.RETEIVE_EVOLUTION_SUCCEDED: {
      return { ...state, evolution: payload, isLoading: false };
    }
    case ACTIONS.RETEIVE_EVOLUTION_FAILED: {
      return {
        ...state,
        error: payload,
        isLoading: false,
        evolution: {},
      };
    }
    default: {
      return state;
    }
  }
};

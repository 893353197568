import { sessionService } from "redux-react-session";
import { ACTIONS } from "./../constants";
export default (
  state = {
    questionnaireEmployee: {},
    questionnaires: [],
    medicalRecord: {},
    isLoading: false,
    error: null,
    user: {},
    info_deleted: false
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: {
      return {
        ...state,
        questionnaireEmployee: {},
        isLoadingRecord: true,
        error: null
      };
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED: {
      return { ...state, questionnaireEmployee: payload, error: null };
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED: {
      return { ...state, questionnaireEmployee: {}, error: null };
    }
    case ACTIONS.CHANGE_PASSWORD_INIT: {
      return { ...state, isLoadingRecord: true, error: null };
    }
    case ACTIONS.CHANGE_PASSWORD_SUCCEDED: {
      return { ...state, questionnaireEmployee: {}, error: null };
    }
    case ACTIONS.CHANGE_PASSWORD_FAILED: {
      return { ...state, questionnaireEmployee: {}, error: null };
    }
    case ACTIONS.CHANGE_DATA_INIT: {
      return { ...state, isLoadingRecord: true, error: null };
    }
    case ACTIONS.CHANGE_DATA_SUCCEDED: {
      return { ...state, questionnaireEmployee: {}, error: null };
    }
    case ACTIONS.CHANGE_DATA_FAILED: {
      return { ...state, questionnaireEmployee: {}, error: null };
    }
    case ACTIONS.DELETE_DATA_INIT: {
      return { ...state, isLoadingRecord: true, error: null };
    }
    case ACTIONS.DELETE_DATA_SUCCEDED: {
      return {
        ...state,
        user: payload.user,
        questionnaires: [],
        questionnaireEmployee: {},
        error: null
      };
    }
    case ACTIONS.DELETE_DATA_FAILED: {
      return { ...state, questionnaireEmployee: {}, error: null };
    }
    case ACTIONS.GET_QUESTIONNAIRES_INIT: {
      return { ...state, questionnaires: [], medicalRecord: {} };
    }
    case ACTIONS.GET_QUESTIONNAIRES_SUCCEDED: {
      return {
        ...state,
        questionnaires: payload.questionnaires,
        medicalRecord: payload.medicalRecord
      };
    }
    case ACTIONS.GET_QUESTIONNAIRES_FAILED: {
      return { ...state, questionnaires: [], medicalRecord: {} };
    }
    case ACTIONS.POST_FIRST_CONNECTION_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ACTIONS.POST_FIRST_CONNECTION_SUCCEDED: {
      sessionService.loadUser().then((res) => {
        sessionService.saveUser({ ...res, firstConnection: false });
      });
      return {
        ...state,
        user: payload,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.POST_FIRST_CONNECTION_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.POST_WELCOME_MSG_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ACTIONS.POST_WELCOME_MSG_SUCCEDED: {
      sessionService.loadUser().then((res) => {
        sessionService.saveUser({ ...res, welcomeMsg: false });
      });
      return {
        ...state,
        user: payload,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.POST_WELCOME_MSG_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.DELETE_ACOUNT_EMPLOYEE_INIT: {
      return { ...state, isLoadingRecord: true, error: null };
    }
    case ACTIONS.DELETE_ACOUNT_EMPLOYEE_SUCCEDED: {
      return {
        ...state,
        questionnaireEmployee: {},
        questionnaires: [],
        medicalRecord: {},
        isLoading: false,
        error: null,
        user: {}
      };
    }
    case ACTIONS.DELETE_ACOUNT_EMPLOYEE_FAILED: {
      return { ...state, error: payload };
    }
    default: {
      return state;
    }
  }
};

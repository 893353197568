import { ACTIONS } from "../constants"


export default (state = { items: [], item: {}, page: 0, isLoading: false, searchTerm: "",
  messages: {}, messagesEmployee:[], hasMore: true, error: false }, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.FETCH_MESSAGES_INIT : {
      return { ...state, isLoading: true, error: null, messages:{} }
    }
    case ACTIONS.FETCH_MESSAGES_SUCCEDED : {
      return { ...state, messages : payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_MESSAGES_FAILED : {
       return { ...state, isLoading: false, error: true, messages:{} }
    }
    case ACTIONS.FETCH_MESSAGES_EMPLOYEE_INIT : {
      return { ...state, isLoading: true, error: null, messagesEmployee:[] }
    }
    case ACTIONS.FETCH_MESSAGES_EMPLOYEE_SUCCEDED : {
      return { ...state, messagesEmployee: payload.conversations, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_MESSAGES_EMPLOYEE_FAILED : {
       return { ...state, isLoading: false, error: true, messagesEmployee: [] }
    }
    case ACTIONS.FILTER_COMPANIES: {
      return { ...state, searchTerm: payload.searchTerm }
    }
    case ACTIONS.POST_NEW_MESSAGES_INIT : {
      return { ...state, isLoading: true, error: false }
    }
    case ACTIONS.POST_NEW_MESSAGES_SUCCEDED : {
       return { ...state, isLoading: false, error: false}
    }
    case ACTIONS.POST_NEW_MESSAGES_FAILED: {
      return { ...state, isLoading: false, error: true}
    }
    default: {
      return state
    }
  }
}
import { ACTIONS } from "../constants";

export default (
  state = {
    questionnaireEmployee: {},
    isLoading: false,
    error: null,
    questionnaires: [],
    notificationsMessages: [],
    messages: [],
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: {
      return {
        ...state,
        questionnaireEmployee: {},
        isLoading: true,
        error: null,
      };
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED: {
      return {
        ...state,
        questionnaireEmployee: payload,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED: {
      return {
        ...state,
        questionnaireEmployee: {},
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.GET_QUESTIONNAIRES_INIT: {
      return { ...state, questionnaires: [] };
    }
    case ACTIONS.GET_QUESTIONNAIRES_SUCCEDED: {
      return { ...state, questionnaires: payload.questionnaires };
    }
    case ACTIONS.GET_QUESTIONNAIRES_FAILED: {
      return { ...state, questionnaires: [] };
    }
    case ACTIONS.FETCH_MESSAGES_INIT: {
      return { ...state, messages: [] };
    }
    case ACTIONS.FETCH_MESSAGES_SUCCEDED: {
      return { ...state, messages: payload.conversations };
    }
    case ACTIONS.FETCH_MESSAGES_FAILED: {
      return { ...state, messages: [] };
    }
    case ACTIONS.FETCH_UNREAD_MESSAGES_INIT: {
      return {
        ...state,
        isLoading: true,
        notificationsMessages: [],
        error: null,
      };
    }
    case ACTIONS.FETCH_UNREAD_MESSAGES_SUCCEDED: {
      return {
        ...state,
        isLoading: false,
        notificationsMessages: payload.notifications,
        error: null,
      };
    }
    case ACTIONS.FETCH_UNREAD_MESSAGES_FAILED: {
      return {
        ...state,
        isLoading: false,
        notificationsMessages: [],
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};

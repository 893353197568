import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// load style
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/main.scss";
//sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_DSN } from "./constants";
import reportWebVitals from "./reportWebVitals";
// loader
import Loader from "./components/Loader";
// store
import configureStore from "./configureStore";
import { sessionService } from "redux-react-session";
import { Provider } from "react-redux";

const store = configureStore();
const { NODE_ENV } = process.env;
NODE_ENV === "production" &&
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        traceXHR: false,
      }),
    ],
    tracesSampleRate: 1.0,
  });

// Init the session service
sessionService
  .initSessionService(store, { refreshOnCheckAuth: true })
  .finally(() =>
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <App />
          </React.Suspense>
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    )
  );
reportWebVitals();

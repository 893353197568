export const ACTIONS = {
  FETCH_ADMINS_INIT: "FETCH_ADMINS_INIT",
  FETCH_ADMINS_SUCCEDED: "FETCH_ADMINS_SUCCEDED",
  FETCH_ADMINS_FAILED: "FETCH_ADMINS_FAILED",

  CLEAR_ADMIN_FORM: "CLEAR_ADMIN_FORM",

  CREATE_ADMIN_INIT: "CREATE_ADMIN_INIT",
  CREATE_ADMIN_SUCCEDED: "CREATE_ADMIN_SUCCEDED",
  CREATE_ADMIN_FAILED: "CREATE_ADMIN_FAILED",

  FETCH_ADMIN_INIT: "FETCH_ADMIN_INIT",
  FETCH_ADMIN_SUCCEDED: "FETCH_ADMIN_SUCCEDED",
  FETCH_ADMIN_FAILED: "FETCH_ADMIN_FAILED",

  EDIT_ADMIN_INIT: "EDIT_ADMIN_INIT",
  EDIT_ADMIN_SUCCEDED: "EDIT_ADMIN_SUCCEDED",
  EDIT_ADMIN_FAILED: "EDIT_ADMIN_FAILED",

  DELETE_ADMIN_INIT: "DELETE_ADMIN_INIT",
  DELETE_ADMIN_SUCCEDED: "DELETE_ADMIN_SUCCEDED",
  DELETE_ADMIN_FAILED: "DELETE_ADMIN_FAILED",

  FILTER_ADMINS: "FILTER_ADMINS",

  FETCH_CONTACTS_INIT: "FETCH_CONTACTS_INIT",
  FETCH_CONTACTS_SUCCEDED: "FETCH_CONTACTS_SUCCEDED",
  FETCH_CONTACTS_FAILED: "FETCH_CONTACTS_FAILED",

  FILTER_CONTACTS: "FILTER_CONTACTS",

  FETCH_QUOTES_INIT: "FETCH_QUOTES_INIT",
  FETCH_QUOTES_SUCCEDED: "FETCH_QUOTES_SUCCEDED",
  FETCH_QUOTES_FAILED: "FETCH_QUOTES_FAILED",

  FILTER_QUOTES: "FILTER_QUOTES",

  FETCH_FORMATIONS_INIT: "FETCH_FORMATIONS_INIT",
  FETCH_FORMATIONS_SUCCEDED: "FETCH_FORMATIONS_SUCCEDED",
  FETCH_FORMATIONS_FAILED: "FETCH_FORMATIONS_FAILED",

  FILTER_FORMATIONS: "FILTER_FORMATIONS",

  FETCH_ADMIN_PERMISSIONS_INIT: "FETCH_ADMIN_PERMISSIONS_INIT",
  FETCH_ADMIN_PERMISSIONS_SUCCEDED: "FETCH_ADMIN_PERMISSIONS_SUCCEDED",
  FETCH_ADMIN_PERMISSIONS_FAILED: "FETCH_ADMIN_PERMISSIONS_FAILED",

  DELETE_CONTACT_INIT: "DELETE_CONTACT_INIT",
  DELETE_CONTACT_SUCCEDED: "DELETE_CONTACT_SUCCEDED",
  DELETE_CONTACT_FAILED: "DELETE_CONTACT_FAILED"
};

export const ENDPOINT = {
  ADMINS: "/api/admins",
  ADMIN: "/api/admin/:param",
  CONTACTS: "/api/user/contact",
  QUOTES: "/api/user/quote",
  FORMATIONS: "/api/user/formation-email",
  ADMIN_PERMISSIONS: "/api/admin/permissions",
  DELETE_CONTACT: "/api/user/contact/:param"
};

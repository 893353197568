import React from "react"

const Results = React.lazy( () => import("../../modules/employee/results/containers/results"))
const Result = React.lazy( () => import("../../modules/employee/results/containers/result"))

export const results = {
  path: "/results",
  component: Results
}

export const result = {
  path: "/result/:param",
  component: Result
}